import styled from 'styled-components'

import { DEVICE } from '../../../util/constants'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 32px repeat(10, 1fr) 32px;
  padding: 40px 0;

  @media ${DEVICE.tabletL} {
    grid-template-columns: 1fr repeat(10, 1fr) 1fr;
    max-width: 1317px;
    padding: 60px 0;
  }
`

export const DetailsContainer = styled.div`
  grid-column: 2 / 12;

  @media ${DEVICE.tabletL} {
    grid-column: ${props => (props.isFullWidth ? '2 / 12' : '2 / 6')};
  }
`

export const MemberType = styled.p`
  color: var(--content-background-dark-blue);
  font-size: 1.4rem;
  letter-spacing: 0.02em;
  line-height: 1.375;
  margin-bottom: 15px;

  @media ${DEVICE.tabletL} {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
`

export const Name = styled.h1`
  color: var(--graphic-accent-purple-2);
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 1.375;
  margin-bottom: 10px;

  @media ${DEVICE.tabletL} {
    font-size: 4.4rem;
  }
`

export const Title = styled.h2`
  color: var(--graphic-accent-purple-2);
  font-size: 2.8rem;
  font-weight: 300;
  line-height: 1.36;

  @media ${DEVICE.tabletL} {
    font-size: 3.6rem;
  }
`

export const ImageContainer = styled.div`
  grid-column: 2 / 12;
  margin-top: 20px;

  @media ${DEVICE.tabletL} {
    grid-column: 7 / 12;
    margin-top: 0;
  }
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

export const BioContainer = styled.div`
  color: var(--content-background-dark-blue);
  font-size: 1.6rem;
  grid-column: 2 / 12;
  line-height: 1.6;
  margin-top: 20px;

  p,
  ul,
  ol,
  h1,
  h2,
  h3 {
    margin: 0 0 20px;
  }

  h4,
  h5,
  h6 {
    margin: 0 0 10px;
  }

  a {
    color: var(--highlight-blue);
    text-decoration: underline;

    &:hover {
      color: var(--content-background-dark-blue);
    }
  }

  @media ${DEVICE.tabletL} {
    font-size: 1.8rem;
    grid-row: 2;
    margin-top: 50px;
  }
`
