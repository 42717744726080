import React from 'react'
import { PrismicRichText } from '@prismicio/react'

import * as Styled from './styles/AuthorDetailContent.styles'

const AuthorDetailContent = ({ bio, image, memberType, name, title }) => (
  <Styled.Container>
    <Styled.DetailsContainer
      isFullWidth={!image.thumbnails?.executive_team_desktop?.url}
    >
      {!!memberType && <Styled.MemberType>{memberType}</Styled.MemberType>}
      <Styled.Name>{name.text}</Styled.Name>
      {!!title.text && <Styled.Title>{title.text}</Styled.Title>}
    </Styled.DetailsContainer>
    {!!image?.thumbnails?.executive_team_desktop?.url && (
      <Styled.ImageContainer>
        <Styled.Image
          src={image.thumbnails.executive_team_desktop?.url}
          alt={image.alt}
        />
      </Styled.ImageContainer>
    )}
    {!!bio.richText && (
      <Styled.BioContainer>
        <PrismicRichText field={bio.richText} />
      </Styled.BioContainer>
    )}
  </Styled.Container>
)

export default AuthorDetailContent
